//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow: hidden;
}

.container-fluid {
  @media(max-width: 600px) {
    padding: 0;
  }
}