//
// topbar.scss
//

.navbar-custom {
  padding: 0 30px 0 37px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: $topbar-height;
  z-index: 100;
  background-color: $bg-topbar-light;
  box-shadow: $box-shadow-sm;

  .topnav-menu {
    > li {
      float: left;
    }
    .nav-link {
      padding: 0 15px;
      color: $menu-item;
      min-width: 32px;
      display: block;
      line-height: $topbar-height;
      text-align: center;
      max-height: $topbar-height;
      cursor: pointer;

      svg {
        height: 20px;
        width: 20px;
        color: $menu-item;
        fill: rgba($menu-item, 0.12);
      }

      &:hover,
      &:focus,
      &:active {
        svg {
          color: $menu-item-active;
          fill: rgba($menu-item-active, 0.1);
        }
      }
    }
  }
  .dropdown.show {
    .nav-link {
      background-color: rgba($white, 0.05);
    }
  }

  .menu-left {
    overflow: hidden;
  }

  /* Search */
  .app-search {
    padding: 17px 0;
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    form {
      max-width: 320px;
    }
    .form-control {
      border: 1px solid transparent;
      height: 38px;
      padding-left: 40px;
      padding-right: 20px;
      background-color: $body-bg;
      box-shadow: none;
      border-radius: 0.3rem;

      &:focus {
        background-color: $white;
        border: 1px solid $body-bg;
      }
    }
    svg {
      position: absolute;
      z-index: 10;
      font-size: 18px;
      color: $gray-500;
      line-height: 38px;
      left: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .button-menu-mobile {
    border: none;
    color: $gray-500;
    display: block;
    height: $topbar-height;
    line-height: $topbar-height;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
    margin-right: 45px;

    &.disable-btn {
      display: none;
    }

    .menu-icon {
      display: inline-block;
    }
    .close-icon {
      display: none;
    }

    &.open {
      .menu-icon {
        display: none;
      }
      .close-icon {
        display: inline-block;
      }
    }
  }

  // Logo
  .logo {
    // width: $leftbar-width;

    span.logo-sm {
      display: none;
    }
  }
}

.text-logo {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1px;
}

/* Notification */
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.noti-scroll {
  max-height: 230px;
}

.notification-list {
  margin-left: 0;

  .noti-title {
    background-color: transparent;
    padding: 7px 20px 15px;
  }

  .noti-icon {
    font-size: 21px;
    vertical-align: middle;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 24px;
    right: 16px;
    width: 12px;
    height: 12px;
    background-color: $danger;
    border: 2px solid $bg-topbar-light;
    border-radius: 50%;
    box-shadow: $box-shadow-lg;
    animation: blinker 3s linear infinite;
    animation-fill-mode: initial;
  }

  .notify-item {
    padding: 12px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 5px;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $gray-800;
      line-height: 1.5;

      b {
        font-weight: 500;
      }
      small {
        display: block;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }
  .profile-dropdown-items {
    .notify-item {
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    }
  }
}

.profile-dropdown {
  display: none;
}

.profile-dropdown-items {
  width: 170px;
  i {
    vertical-align: middle;
    margin-right: 5px;
  }
}

.nav-user {
  padding: 0 12px !important;
  img {
    height: 32px;
    width: 32px;
  }
}

@media (max-width: 1024px) {
  .navbar-custom {
    left: 0;
    padding: 0 30px 0 10px;
    margin: 0px;
    .button-menu-mobile.disable-btn {
      display: block;
    }

    .logo {
      display: none;
    }
  }
}

@include media-breakpoint-down(xs) {
  .navbar-custom {
    .page-title {
      display: none;
    }
  }
}
